import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { EMPTY, Observable, Subject, throwError, timer } from "rxjs";
import { EventsService } from "../helpers/events.service";
import { HelperService } from "../helpers/helper.service";
import { catchError, finalize, takeUntil, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable()
export class InterceptInterceptor implements HttpInterceptor {
  constructor(
    private helper: HelperService,
    private eventsService: EventsService,
    public router: Router
  ) {}
  isVerified: boolean;
  private unsubscribe$ = new Subject<void>();

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.eventsService.isverifiedObser.subscribe((res) => {
      this.isVerified = res;
    });
    if (
      request.url.includes("users") ||
      request.url.includes("gateway") ||
      request.url.includes("receipt") ||
      request.url.includes("payment/method") ||
      request.url.includes("invoices/getUrlData") ||
      request.url.includes("report/count_unpaid_tenant") ||
      request.url.includes("fawryReferenceNumber") ||
      request.method === "GET" ||
      request.method === "POST" ||
      request.method === "PUT"
    ) {
      if (
        request.url.includes("users/login") ||
        request.url.includes("payment/method") ||
        request.url.includes("invoices/getUrlData") ||
        request.url.includes("report/count_unpaid_tenant") ||
        request.url.includes("receipt_getfees") ||
        request.url.includes("fawryReferenceNumber") ||
        request.url === `${environment.URL}users` ||
        request.url === `${environment.URL}users/resend` ||
        request.url === `${environment.URL}users/verify`
      ) {
        return next.handle(request);
      } else {
        this.helper.showSpinner();
        return next
          .handle(
            request.clone({
              setHeaders: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
          )
          .pipe(
            finalize(() => {
              this.helper.hideSpinner();
            }),
            takeUntil(this.unsubscribe$),
            catchError((error) => {
              if (error.status == 401) {
                this.router.navigate(["auth/login"]);
                localStorage.clear();
                this.unsubscribe$.next();
                timer(0).subscribe(() => {
                  this.helper.showToastDanger("Error", error.error.message);
                });
                return throwError(error);
              } else if (error.status == 403) {
                this.unsubscribe$.next();
                timer(0).subscribe(() => {
                  this.helper.showToastDanger("Warning", error.error.message);
                });
              }
              return throwError(error);
            })
          );
      }
    } else {
      if (this.isVerified === false) {
        this.helper.hideSpinner();
        this.helper.showToastWarning(
          "Warning",
          "Please verify your email account to proceed."
        );
        return EMPTY;
      }
    }
  }
}
