<div [ngClass]="{ container: true, 'rtl-container': lang === 'ar' }">
  <div *ngIf="getUrlData?.invoice?.receiver?.logo" class="text-center">
    <img class="company-logo" [src]="getUrlData?.invoice?.receiver?.logo" alt="copmany logo" />
  </div>
  <div class="row">
    <div class="col-12">
      <p class="invoice">{{ "Invoices" | translate }}</p>
    </div>
    <div class="col-12" *ngIf="showInvices">
      <span class="invoice-span">{{
        "Select at leaset one invoice" | translate
        }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7 col-sm-12 offset-lg-0">
      <div class="box-shadow mt-5 p-3" *ngIf="invoices == null || invoices.length < 1">
        <h1 class=" main-font">
          {{ "NO CURRENT INVOICES" | translate }}
        </h1>
      </div>

      <div *ngFor="let invoice of invoices; let i = index">
        <div *ngIf="invoice?.status !== '2' && !showInvices" class="container" id="{{ i }}">
          <div class="main-border">
            <div class="row">
              <div class="col ar-left">
                <span class="past {{
                    past_due(invoice?.end_date) ? 'past-col' : 'due-col'
                  }}">
                  {{
                  past_due(invoice?.end_date)
                  ? ("Past Due" | translate)
                  : ("Due" | translate)
                  }}
                </span>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{ "Unit Name" | translate }}</span>: {{ invoice?.unit_name }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{ "Amount" | translate }}</span> :
                  {{ formateService.formatNum(total_amount) }}
                  {{ "KES" | translate }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{ "Discount" | translate }}</span> :
                  {{ total_discount }} {{ "KES" | translate }}
                </p>
                <p [formGroup]="partialForm" *ngIf="partialChecked"
                  class="main-font mid-font font-weight-bold form-group">
                  <span class="col-gray">{{
                    "Partial Amount" | translate
                    }}</span>
                  :
                  <input type="number" (input)="partialChange($event, invoice)" [(ngModel)]="partial_amount"
                    [placeholder]="'0.00' | translate" formControlName="partial_amount" />
                  {{ "KES" | translate }}
                  <span *ngIf="partialForm.controls.partial_amount.touched">
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.errors?.pattern
                      ">
                      {{
                      "Partial Amount should be positive number" | translate
                      }}!
                    </p>
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.value >=
                        invoice.amount
                      ">
                      {{
                      "Partial Amount should be less than Total Amount"
                      | translate
                      }}!
                    </p>
                  </span>
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{ "Period" | translate }}</span> :
                  {{ invoice?.start_date }} -
                  {{ invoice?.end_date }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{
                    "Contract Name" | translate
                    }}</span>
                  :
                  {{ invoice?.contract_name }}
                </p>
                <div class="row">
                  <div class="col-md-6">
                    <p class="main-font mid-font font-weight-bold">
                      <span class="col-gray">{{
                        "Owner Name" | translate
                        }}</span>
                      :
                      {{ invoice?.owner }}
                    </p>
                  </div>
                  <div class="col-md-6 partial">
                    <input type="checkbox" disabled name="pay" id="partial" [(ngModel)]="partialChecked"
                      (change)="onChangeShow($event)" />
                    <label for="partial" class="rent font-weight-bold">
                      {{ "Use partial payment" | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="invoice?.status == '2'" class="paid-invoice">
          {{ "Invoice already paid" | translate }}
        </div>
        <div *ngIf="!showInvices && invoice?.status !== '2'" class="btn pl-5">
          <button [disabled]="invoicesCount <= 1" (click)="showAllInvices()" type="button" class="btn bg-color-orange">
            {{ "Show All Invoices" | translate }}
            <i class="fa fa-bell position-relative">
              <span *ngIf="invoicesCount > 1" class="translate-middle badge rounded-pill bg-danger" class="{{
                  lang === 'en' ? 'badge' : 'badgertl'
                }} translate-middle  rounded-pill bg-danger">
                {{ invoicesCount }}
              </span>
            </i>
          </button>
        </div>
        <div *ngIf="showInvices" class="btn pl-5">
          <button (click)="showAllInvices()" type="button" class="goBack">
            {{ "Go Back " | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="showInvices" class="d-flex selectAll">
        <input type="checkbox" [checked]="isSelectedAll" name="pay" id="select" (change)="selectAll($event)" />
        <label for="select" class="rent font-weight-bold">
          {{ "Select All" | translate }}</label>
      </div>
      <div *ngFor="let invoice of allInvoices; let i = index">
        <div *ngIf="showInvices">
          <div class="main-border layout">
            <div class="row">
              <div class="col ar-right">
                <input [disabled]="invoice?.status.active === false" type="checkbox" name="pay" id="check{{ i }}"
                  [(ngModel)]="invoice.isChecked" (change)="selectInvoiceCard($event, i, invoice)"
                  [checked]="invoice?.status.checked" />
                <label for="check{{ i }}" class="rent font-weight-bold">
                  {{ invoice?.contract_type | translate }}</label><br />
              </div>
              <div class="col ar-left">
                <span class="past {{
                    past_due(invoice?.date_to) ? 'past-col' : 'due-col'
                  }}">
                  {{
                  past_due(invoice?.date_to)
                  ? ("Past Due" | translate)
                  : ("Due" | translate)
                  }}
                </span>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{ "Unit Name" | translate }}</span>
                  : {{ invoice?.contract?.unit.name }}
                </p>

                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{ "Amount" | translate }}</span> :
                  {{
                  calculateTotal(invoice?.amount_remaining, invoice?.discount)
                  }}
                  {{ "KES" | translate }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{ "Discount" | translate }}</span> :
                  {{ invoice?.discount || "0.00" }} {{ "KES" | translate }}
                </p>
                <p [formGroup]="partialForm" *ngIf="
                   partialCheckedShow && invoicesCheckedData.length - 1 === i
                  " class="main-font mid-font font-weight-bold form-group">
                  <span class="col-gray">{{
                    "Partial Amount" | translate
                    }}</span>
                  :
                  <input type="number" (keyup)="lastPartialChange($event, invoice)" [(ngModel)]="partial_amount"
                    [placeholder]="'0.00' | translate" formControlName="partial_amount" />
                  {{ "KES" | translate }}

                  <span *ngIf="partialForm.controls.partial_amount.touched">
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.errors?.pattern
                      ">
                      {{
                      "Partial Amount should be positive number" | translate
                      }}!
                    </p>
                    <p class="status-danger" *ngIf="
                        partialForm.controls.partial_amount.value >=
                        invoice.amount_remaining
                      ">
                      {{
                      "Partial Amount should be less than Total Amount"
                      | translate
                      }}!
                    </p>
                  </span>
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{ "Period" | translate }}</span> :
                  {{ invoice?.date_from }} -
                  {{ invoice?.date_to }}
                </p>
                <p class="main-font mid-font font-weight-bold">
                  <span class="col-gray">{{
                    "Contract Name" | translate
                    }}</span>
                  :
                  {{ invoice?.contract?.contract_name }}
                </p>

                <div class="row">
                  <div class="col-md-6">
                    <p class="main-font mid-font font-weight-bold">
                      <span class="col-gray">{{
                        "Owner Name" | translate
                        }}</span>
                      :
                      {{ invoice?.contract?.firstparty?.name }}
                    </p>
                  </div>
                  <div class="col-md-6 partial" *ngIf="
                      invoicesCheckedData.length - 1 === i ||
                      (allInvoices.length - 1 === i &&
                        allInvoices[i].status.checked)
                    ">
                    <input [checked]="partialCheckedShow" type="checkbox" name="pay" id="partial" [value]="i"
                      (change)="onChangePartial($event, i,invoice)" disabled/>
                    <label for="partial" class="rent font-weight-bold">
                      {{ "Use partial payment" | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-sm-12 offset-lg-0 ar-text-right" *ngIf="invoices.length> 0">
      <div class="main-border">
        <p class="summary col-gray">{{ "Payment Options" | translate }} :</p>
        <hr />
        <div class="sec-container">
          <div class="row mgbtm d-flex align-items-center ">
            <div class="col-sm-1">
              <input type="radio" name="method" id="credit" value="credit" (change)="methodChange('m-pesa')" />
            </div>
            <div class="col-sm-6">
              <label class="main-font grdlbl" for="credit">
                {{ "M-Pesa" | translate }}
              </label>
            </div>
            <div class="col-sm-5 d-flex">
              <img class="{{ lang === 'en' ? 'float-right' : 'float-left' }} pr-2 w-50"
                src="assets/images/m-pesa.png" />
              <!-- <img
                class="{{ lang === 'en' ? 'float-right' : 'float-left' }} pr-2"
                src="../../../assets/images/meeza.png"
              />
              <img
                class="{{ lang === 'en' ? 'float-right' : 'float-left' }} pr-2"
                src="../../../assets/icons/visa.svg"
              />
              <img
                class="{{ lang === 'en' ? 'float-right' : 'float-left' }} pr-2"
                src="../../../assets/icons/Master.svg"
              /> -->
            </div>
          </div>

          <!-- <div class="row mgbtm">
            <div class="col-sm-1">
              <input [disabled]="showInvices" type="radio" name="method" id="fawry" value="fawry"
                (change)="methodChange('fawry')" />
            </div>
            <div class="col-sm-6">
              <label class="main-font mid-font grdlbl" for="fawry">
                {{ "Fawry payment" | translate }}
              </label>
            </div>
            <div class="col-sm-5">
              <img
                class="{{ lang === 'en' ? 'float-right' : 'float-left' }}"
                src="../../../assets/images/fawry.png"
                alt=""
              />
            </div>
          </div> -->

          <!-- <div class="row mgbtm">
            <div class="col-sm-1">
              <input
                type="radio"
                name="method"
                id="installment"
                value="installment"
                (change)="methodChange('installment')"
              />
            </div>
            <div class="col-sm-6">
              <label class="main-font mid-font grdlbl nowrap" for="installment">
                {{ "Pay with Installment" | translate }}
              </label>
            </div>
            <div class="col-sm-5">
              <img
                class="{{
                  lang === 'en' ? 'float-right' : 'float-left'
                }} pr-3 bank"
                src="../../../assets/icons/icon_banks.svg"
                alt=""
              />
            </div>
          </div> -->

          <!-- <div
            *ngIf="payment_method === 'installment'"
            class="container d-flex"
          >
            <button
              (click)="select_installment($event,'contact')"
              [ngClass]="{ selected_value: install_value === 'contact' }"
              class="month-div w-25"
            >
              <img class="w-100" src="../../../assets/icons/Contact Logo.svg">
            </button>
          </div> -->
        </div>
        <hr />
        <p class="summary col-gray">{{ "Payment Summary" | translate }} :</p>
        <hr />
        <div class="container font-weight-bold">
          <div *ngIf="partialChecked || partialCheckedShow" class="row">
            <div class="col-12 text-black mid-font pl-4">
              {{ "You are about to pay" | translate }}
              <span class="col-orange">{{
                formateService.formatNum(total)
                }}</span>
              {{ "KES" | translate }}
              {{ "out of" | translate }}
              <span class="col-orange">
                {{ formateService.formatNum(total_invoice_amount) }}
              </span>
              {{ "KES" | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Subtotal" | translate }}
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formateService.formatNum(subtotal) }} {{ "KES" | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Discount" | translate }}
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formateService.formatNum(total_discount) }}
              {{ "KES" | translate }}
            </div>
          </div>
          <div *ngIf="partialChecked || partialCheckedShow" class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Remaining" | translate }}
            </div>
            <div class="col-6 text-black mid-font text-right pr-5">
              {{ formateService.formatNum(total_invoice_amount - total) }}
              {{ "KES" | translate }}
            </div>
          </div>

          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Fees" | translate }}
            </div>
            <div class="col-6 mid-font text-black text-right pr-5">
              {{ formateService.formatNum(total_fees) }} {{ "KES" | translate }}
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-6 text-black mid-font pl-4">
              {{ "Total" | translate }}
            </div>
            <div class="col-6 mid-font col-orange text-right pr-5">
              {{ formateService.formatNum(finalTotal) }}
              {{ "KES" | translate }}
            </div>
          </div>
          <div class="row terms">
            <!-- <div class="col-1">
                <input type="checkbox" id="terms" #terms />
              </div> -->
            <div class="col-10 d-flex">
              <input type="checkbox" value="policy" (change)="checkPolicy($event)" class="mx-2" id="terms" />
              <label for="terms">
                <p>
                  "{{
                  "By clicking Pay Now button, you agree that you checked our "
                  | translate
                  }}
                  <a routerLink="/privacy-policy" target="_blank">{{ "Payment Policy" | translate }}"</a>
                </p>
              </label>
            </div>
          </div>
          <div class="row pt-3 checkout-btns">
            <div class="desktopview">
              <a routerLink="/"><button class="btn text-white cancel font-weight-bold">
                  {{ "Cancel" | translate }}
                </button></a>
              <button class="btn text-white checkout font-weight-bold" (click)="checkout()" [disabled]="
                  total <= 0 ||
                  payment_method === null ||
                  !partialForm.valid ||
                  !policyChecked|| (!install_value &&payment_method=='installment' )
                ">
                {{ "Pay Now" | translate }}
              </button>
            </div>
            <div class="mobileview">
              <button class="btn text-white checkout font-weight-bold" (click)="checkout()" [disabled]="
                  total <= 0 ||
                  payment_method === null ||
                  !partialForm.valid ||
                  !policyChecked||  (!install_value &&payment_method=='installment' ) ||  (!cbk_type &&payment_method=='credit' )
                ">
                {{ "Pay Now" | translate }}
              </button>
              <a routerLink="/"><button class="btn text-white cancel font-weight-bold">
                  {{ "Cancel" | translate }}
                </button></a>
            </div>
          </div>
        </div>
      </div>
      <div class="main-border">
        <hr />
        <p class="mid-font text-black ml-3 font-weight-bold">
          {{ "Contact an expert to support you" | translate }}
        </p>
        <a href="tel:+201096504044" class="contact-link">
          <button class="col-orange btn contact mid-font mb-3 font-weight-bold">
            {{ "Contact us" | translate }}
          </button>
        </a>
      </div>
    </div>
  </div>
</div>