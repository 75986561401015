import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  searchParams : Subject<any> = new Subject()
  private searchParamsSource = new BehaviorSubject('');
  currentParams = this.searchParamsSource.asObservable();
  installment : Subject<any> = new Subject()
  private installmentSource = new BehaviorSubject('');
  currentinstallment= this.installmentSource.asObservable();
  constructor() { }

  storeSearchParams(params: any) {
    this.searchParamsSource.next(params)
  }
  storeInstallment(amount: any) {
    this.installmentSource.next(amount)
  }
}
