import { Component, OnInit } from "@angular/core";
import { AppService } from "../../services/helpers/app.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HelperService } from "../../services/helpers/helper.service";
declare var Checkout: any;
@Component({
  selector: "ngx-payer-info",
  templateUrl: "./payer-info.component.html",
  styleUrls: ["./payer-info.component.scss"],
})
export class PayerInfoComponent implements OnInit {
  credintialsObj = {
    merchant_reference_id: "25552",
    customer_merchant_profile_id: "13",
    customer_name: "John Doe",
    customer_email: "example@gmail.com",
    customer_mobile: "01129365372",
    amount: "10",
    signature:
      "98ade9dc50bcb54150d5e964dc2398b1ac7028442f6f4fd4d9f93538ca8e2981",
    description: "Charge request description",
    card_number: "5123450000000008",
    cvv: "123",
    expiry_month: "05",
    expiry_year: "26",
  };
  merchant_reference_id;
  price;
  invoiceid;
  data;
  payment_method: any = "visa";
  constructor(
    private avroute: ActivatedRoute,
    private appservice: AppService,
    public router: Router,
    private helper: HelperService
  ) {
    this.avroute.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.data = this.router.getCurrentNavigation().extras.state.data;
      }
    });
    this.price = this.avroute.snapshot.params["price"];
    this.invoiceid = this.avroute.snapshot.params["invoiceId"];
  }

  ngOnInit(): void {
    this.NBE_Init();
  }

  NBE_Init() {
    if (this.data == undefined || this.data == null) {
      window.history.back();
    } else {
      Checkout.configure({
        session: {
          id: this.appservice.NBE_session_id,
        },
        order: {
          amount: this.price,
          currency: "KES",
          description: this.data.invoice.contract.contract_name,
          id: this.invoiceid,
        },
        interaction: {
          operation: "PURCHASE",
          merchant: {
            name: this.data.invoice.payer.name,
            address: {
              line1: "200 Sample St",
              line2: "1234 Example Town",
            },
          },
        },
      });
    }
  }
  submit() {
    if (this.payment_method === "visa") {
      Checkout.showPaymentPage();
    }
  }


  addevent() {
    document.addEventListener("click", (evt) => {
      const flyoutElement = document.getElementById("cc-payment-form");
      const targetElement = evt.target;
      if (targetElement === flyoutElement) {
        return;
      } else {
        document.getElementById("myModal").style.display = "none";
      }
    });
  }
  postinvoice() {
    const invoiceObj = {};
    invoiceObj[this.invoiceid] = this.price;
    const sentObj = {
      payment_method: 1,
      invoices: invoiceObj,
      user_id: localStorage.getItem("userId"),
    };
    this.appservice.POST(`receipt`, sentObj).subscribe(
      () => {
        this.helper.showToastSuccess(
          "Thanks for using Boyot",
          "Payment successfully"
        );
        window.location.href = "https://e-rent.boyot.app/";
      },
      (err) => {
        this.helper.hideSpinner();
        this.helper.showToastDanger(
          "Error",
          err.error.message[Object.keys(err.error.message)[0]][0]
        );
      }
    );
  }
}
